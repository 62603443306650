.summary {
  max-width: 900px;
  margin: auto;
}

.customer-info--field {
  max-width: 350px;
}

.input-fields {
  display: flex;
  flex-direction: row;
}

.input-fields > div {
  margin-top: 16px;
}

.customized-select--summary {
  border: 1px solid black !important;
}

.customized-select--summary:focus {
  border: 2px solid black !important;
}

.select-default--summary {
  color: grey;
}