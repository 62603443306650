nav#nav-buttons > div {
  margin-right: 6px;
  /*background: blue;*/
}

.nav-button {
  color: black;
  outline: none !important;
  background: white;
  border: 1px solid var(--main-color);
  width: 45px;
  height: 27px;
  font-size: 16px;
  cursor: pointer;
}

.button-text {
  color: gray;
  text-align: center;
  font-size: 11px;
  width: 50px;
  margin: 0px;
}

.active--button-text {
  color: black !important;
}

.active-button {
  background: var(--main-color) !important;
  height: 31px;
  margin-top: -2px;
  color: white;
}
