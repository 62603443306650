@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(../fonts/Inter/Inter-VariableFont_slnt,wght.ttf) format("truetype");
}

* {
  font-family: "Inter", sans-serif !important;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: var(--background-color);
}

/* * {
  scrollbar-width: 4px;
  scrollbar-color: #0070b8;
}

*::-webkit-scrollbar {
  width: 5px;  
  height: 0px;             
}

*::-webkit-scrollbar-track {
  background: rgb(230, 230, 230); 
}

*::-webkit-scrollbar-thumb {
  background-color: #0070b8;    
  border-radius: 20px;
  border: 1px solid rgb(230, 230, 230);      
} */

#root {
  margin: auto;
}

.responsive-image {
  width: 100%;
  height: auto;
}

iframe {
  width: 100%;
  height: 80vh;
  border: none;
}

@media screen and (max-width: 900px) {
  iframe{
    height: 70vh;
    margin-top: 20px;
  }
}

@media screen and (max-width: 400px) {
  iframe{
    height: 60vh;
    margin-top: 20px;
  }
}

@media screen and (max-height: 800px) {
  iframe{
    height: 68vh;
  }
}