:root {
  --main-color: #0070b8;
  --text-blue-color: #3f8bb9;
  --background-color: #ffffff;
  --app-container-color: #ffffff;
  --main-gray-color: #6b6a6a;
  --lines-gray-color: #bebbbb;
}

#root {
  width: auto;
  height: auto;
  background: var(--app-container-color);
  margin: auto;
  padding-bottom: 10px;
  padding-top: 30px;
}

.loading {
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#page-header-large--screen {
  display: none;
  flex-flow: row wrap;
}

#page-header-small--screen {
  display: flex;
  flex-flow: row wrap;
}

#title-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#title-section img {
  height: 60px !important;
  margin: auto;
}

.main-title {
  color: var(--text-blue-color);
  font-weight: normal;
  font-size: 29px;
  text-align: center;
}

.subtitle {
  font-weight: normal;
  color: var(--main-gray-color);
  margin: 0px;
  font-size: 16px;
  text-align: center;
}

#nav-buttons {
  /*background: purple;*/
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 5px;
  flex: 1 100%;
}

#btn-menu {
  display: none;
}

#main-content {
  position: relative;
  width: auto;
  margin-top: 32px;
  margin: 0;
  /*background: green;*/
}

.btn-logo {
  cursor: pointer;
  transition: all 0.5s;
}

.button--nav {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
}
.nav--button {
  width: 163px;
  height: 31px;
  z-index: 500;
}
#show-hide-button {
  color: var(--app-container-color);
  border: 1px solid var(--app-container-color);
  background: var(--main-color);
  text-align: center;
}

.summary--button {
  color: var(--main-color);
  border: 1px solid var(--main-color);
  background: var(--app-container-color);
  text-align: center;
}

#show-hide-button[data-edit-text]::after {
  font-size: 12px;
}

section.parameters {
  position: absolute;
  transition: top 0.5s;
  top: -3000px;
  /* left: -100vw; */
  left: 0px;
  width: 100vw;
  height: auto;
  min-height: 78vh;
  z-index: 100;
  padding-bottom: 20px;
  /* background-color: rgb(10,10,10,0.2); */
  /* overflow-y: auto;
  overflow-x: hidden; */
  background: white;
  /* -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px); */
}

.parameters--position {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#model-container {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 50px;
  left: 0px;
  display: flex;
  flex-direction: row;
  margin: 0px;
  flex: 2;
}

#btn-menu:checked ~ * section.parameters {
  transition: all 0.5s;
  top: 0px;
  /* left: 0px; */
}
#btn-menu:checked ~ * .btn-logo {
  display: inherit;
  transition: all 0.5s;
}

#btn-menu:checked ~ * #arrow {
  transform: none;
  border: none;
}

#btn-menu:checked ~ * #arrow::after {
  position: absolute;
  top: -9px;
  left: 3px;
  content: "\d7";
  font-size: 30px;
  color: var(--main-color);
}
.button--text {
  cursor: pointer;
  font-size: 18px;
  padding: 0 5px;
}
.show-hide--see {
  display: none;
}
.show-hide--edit {
  display: initial;
}

#btn-menu:checked ~ * .show-hide--edit {
  display: none;
}

#btn-menu:checked ~ * .show-hide--see {
  display: inherit;
}
#btn-menu:checked ~ * #show-hide-button[data-see3d-text]::before {
  text-align: center;
  font-size: 12px;
}

.horizontal-line {
  height: 1px;
  background-color: var(--lines-gray-color);
}

.vertical-line {
  width: 1px;
  height: 790px;
  background-color: var(--lines-gray-color);
  margin-right: 15px;
  display: none;
}

#horizontal-line-large-screen {
  display: none;
  margin-bottom: 10px;
}

#horizontal-line-small-screen {
  margin-left: 15px;
  margin-right: 15px;
}

.vertical-line + div {
  width: 100%;
}

@media all and (min-width: 650px) {
  .subtitle,
  .main-title {
    text-align: left;
    margin-left: 10px;
  }

  #title-section,
  #nav-buttons {
    flex: auto;
  }
}

@media all and (min-width: 800px) {
  .main-title {
    font-size: 34px;
  }
}

@media all and (min-width: 900px) {
  .btn-logo {
    display: none;
  }

  #main-content,
  #model-container,
  section.parameters {
    position: static;
  }

  section.parameters {
    width: 100%;
    height: 78vh;
    transition: none;
    overflow-y: auto;
    overflow-x: hidden;
  }

  #model-container {
    width: 65%;
    top: 0px;
  }

  #main-content {
    display: flex;
    flex-direction: row;
    margin: 0px;
  }

  #page-header-large--screen {
    display: flex;
  }

  #page-header-small--screen {
    display: none;
  }

  #horizontal-line-large-screen {
    display: block;
  }
  #horizontal-line-small-screen {
    display: none;
  }

  .vertical-line {
    display: static;
  }

  #root {
    padding-left: 10px;
    padding-right: 10px;
  }

  .subtitle,
  .main-title {
    text-align: center;
  }
}

@media all and (min-width: 1030px) {
  .subtitle,
  .main-title {
    text-align: left;
  }
}

/* @media screen and (max-height: 700px) {
  section.parameters {
    height: 70vh;
  }
}

@media screen and (max-height: 800px) {
  section.parameters {
    height: 75vh;
  }
} */

@media screen and (min-width: 900px) {
  .nav--button {
    display: none;
  }
}
