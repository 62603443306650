.page1-box {
  width: 70%;
  height: 5rem;
  border: 1px solid black;
}

.page-title {
  color: var(--text-blue-color);
}
.field--margin{
  margin-top: 15px;
}
.page-subtitle {
  color: var(--main-gray-color);
}

.box--disabled {
  pointer-events: none;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: gray;
  border-radius: 50%;
}

.flex-container {
  display: flex;
}

.color-box {
  min-width: 15px;
  width: 15px;
  height: 15px;
  border: 1px solid gray;
  margin-right: 16px;
  margin-top: 5px;
}

.color-name {
  font-size: 15px;
}

input[type="radio"] {
  -ms-transform: scale(1.2); /* IE 9 */
  -webkit-transform: scale(1.2); /* Chrome, Safari, Opera */
  transform: scale(1.2);
}


.onoffswitch {
  position: relative; 
  width: 64px;
  -webkit-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none;
}
.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.onoffswitch-label {
  display: block; overflow: hidden; cursor: pointer;
  border: 1px solid black; border-radius: 20px;
}
.onoffswitch-inner {
  display: block; width: 200%; margin-left: -100%;
  transition: margin 0.15s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
  display: block; float: left; width: 50%; height: 16px; padding: 0; line-height: 16px;
  font-size: 13px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: attr(data-text-yes);
  padding-left: 8px;
  background-color: #FFFFFF; color: #3F8BB9;
}
.onoffswitch-inner:after {
  content: attr(data-text-no);
  padding-right: 8px;
  background-color: #FFFFFF; color: #999999;
  text-align: right;
}
.onoffswitch-switch {
  display: block; width: 11px; height: 11px; margin: 3.5px;
  background: #A1A1A1;
  position: absolute; top: 0; bottom: 0;
  right: 44px;
  border-radius: 20px;
  transition: all 0.15s ease-in 0s; 
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px; 
  background-color: #27A1CA; 
}

.modal--background {
  background-color: rgb(10,10,10,0.2);
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  z-index: 9999;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  padding: 20px;
}
.modal--card {
  max-height: 600px;
  margin: 20px 25vw;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--app-container-color);
  border: 1px solid var(--lines-gray-color);
}
.modal-button--style{
  margin-top: auto;
  margin-bottom: 20px;
  max-width: 330px;
  background-color: var(--main-color);
  color: var(--app-container-color);
}
.modal-text{
  font-size: medium;
}

@media screen and (max-width: 900px) {
  .modal-text{
    font-size: small;
  }
  .info--button-row{
    margin-top: -10px;
  }
}

@media screen and (max-width: 1200px) {
  .onoffswitch {
    transform: scale(0.9);
  }
}

