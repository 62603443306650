.back-next--buttons {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  z-index: 150;
  margin-left: auto;
  margin-right: 20px;
  background-color: white;
  height: 40px;
  margin-top: -8px;
}
.back-next--buttons > .summary-button--desktop {
  display: none;
}

.button-back-next {
  border: 0;
  padding: 0;
  background-color: transparent;
  outline: none !important;
}
.button-holder-back {
  background-color: #0070b8;
  padding: 3px;
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.button-triangle--back {
  margin-right: 3px;
  width: 16px;
  height: 16px;
  border-bottom: 3px solid white;
  border-left: 3px solid white;
  transform: rotate(45deg);
  border-radius: 15%;
}

.button-white--background {
  background: white;
  color: gray;
  padding-left: 11px;
  padding-right: 11px;
}

.button-holder-next {
  background-color: #0070b8;
  padding: 3px;
  display: flex;
  align-items: center;
  padding-right: 16px;
}

.button-next-white-background {
  padding-left: 11px;
  padding-right: 11px;
}

.button-triangle--next {
  margin-left: 3px;
  width: 16px;
  height: 16px;
  border-top: 3px solid white;
  border-right: 3px solid white;
  transform: rotate(45deg);
  border-radius: 15%;
}

.summary-button--visibility {
  display: none;
}

#btn-menu:checked ~ * .summary-button--visibility {
  display: none;
}

.summary-button--desktop {
  color: var(--main-color);
  border: 1px solid var(--main-color);
  background: var(--app-container-color);
  text-align: center;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  outline: none !important;
}

@media all and (min-width: 900px) {
  .back-next--buttons {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    width: auto;
    margin-left: 20px;
  }

  .back-next--buttons > .summary-button--desktop {
    display: block;
  }
}

@media all and (max-width: 900px) {
  .summary-button--visibility {
    display: initial;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 10px;
  }
}
