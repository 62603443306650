div#parameters-container {
  padding-top: 50px;
  max-width: 400px;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
}

section.parameters-section {
  /*background: yellow;*/
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

section.in-between {
  margin-top: 30px;
}

div.parameters-title {
  /*background: purple;*/
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}

div.horizontal-line--container {
  max-width: 80%;
  margin: auto;
}

div.section-element--container {
  max-width: 60%;
  margin: auto;
}

@media all and (min-width: 900px) {
  div#parameters-container {
    padding-top: 44px;
  }
}

@media all and (max-width: 900px) {
  div#parameters-container {
    padding-top: 100px;
    height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

h3.parameters-section--title {
  font-weight: normal;
  font-size: 16px;
  margin: 0px;
  color: var(--text-blue-color);
  margin-right: 10px;
}

h3.title-margin {
  margin-left: 20px;
  margin-top: 20px;
}

div.message-container {
  width: 85%;
  text-align: center;
  color: red;
  font-size: 14px;
}

div.warning-message-page2,
div.warning-message-page5{
  color: red;
  font-size: 14px;
  text-align: center;
  margin-bottom: 1rem;
}

div.warning-message-page6{
  color: red;
  font-size: 14px;
  margin-top: 2rem;
  text-align: justify;
}

.roof-styles{
  margin-top: 1.5rem;
}